<template>
	<div class="pay-table-partial-container">
		<div class="content">
			<div class="row space-between align-start">
				<div class="section-title">{{ getString('select_what_you_ordered') }}</div>
				<div class="clear-button" v-if="Object.keys(selectedItems).length > 0 || partialGhostAmount > 0" @click="selectedItems = {}; partialGhostAmount = 0">
					<div class="clear-icon" v-html="svgStore.get('iconBin')"></div>
					<p>{{ getString('clear') }}</p>
				</div>
			</div>
			<div class="items-container">
				<div class="section-inset section-item" v-for="item in tableOrdersItems" :key="item.order_item_id" @click="addItem(item)">
					<div class="row space-between centered item-head">
						<div class="left-part">
							<div class="item-quantity">{{ 'x'+(item.quantity - item.redeemed) }}</div>
							<div class="item-name">{{ item.name }}</div>
						</div>
						<div class="right-part">
							<div class="item-price">{{ currencyStore.readableCurrencyValue(item.price) }}</div>
							<div class="select-counter-container" :class="{hidden: !selectedItems[item.order_item_id]}">
								<div class="select-counter">{{ selectedItems[item.order_item_id] ? selectedItems[item.order_item_id] : 0 }}</div>
							</div>
						</div>
					</div>
					<div class="composite-container" v-if="item.products.length > 0">
						<div class="composite-item" v-for="product in item.products">
							<div class="left-composite">
								<div class="product-quantity">{{ 'x'+product.quantity }}</div>
								<div class="product-name">{{ product.product_name }}</div>
							</div>
							<div class="right-composite">
								<div class="item-price" v-if="product.price > 0">{{ currencyStore.readableCurrencyValue(product.price) }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ghost-product row space-between centered item-head" v-if="ghostValue > 0" @click="ghostSheetActive = true; partialGhostAmountEdit = ghostValue">
					<div class="left-part">
						<div class="item-name">{{ getString('amount_left') }}</div>
					</div>
					<div class="right-part">
						<div class="item-price">{{ currencyStore.readableCurrencyValue(ghostValue) }}</div>
						<div class="select-counter-container" :class="{hidden: partialGhostAmount <= 0}">
							<div class="select-counter">{{ currencyStore.readableCurrencyValue(partialGhostAmount) }}</div>
						</div>
					</div>
				</div>
				<div class="no-items" v-if="tableOrdersItems.length === 0 && ghostValue - partialGhostAmount <= 0">{{ getString('no_items') }}</div>
			</div>
		</div>
		<div class="fixed-bottom">
			<div class="row space-between centered row-pay-info">
				<div class="pay-info">{{ getString('amount_to_pay') }}</div>
				<div class="pay-value-container">
					<div class="pay-value-discount" v-show="actionsStore.payBillAmount < intentToPay">{{ currencyStore.readableCurrencyValue(intentToPay) }}</div>
					<div class="pay-value-full" :class="{discount: actionsStore.payBillAmount < intentToPay}">{{ currencyStore.readableCurrencyValue(actionsStore.payBillAmount < intentToPay ? actionsStore.payBillAmount : intentToPay) }}</div>
				</div>
			</div>
			<PayTableActions :amount="actionsStore.payBillAmount < intentToPay ? actionsStore.payBillAmount : intentToPay" :payment-type="3" :items-redeemed="itemsRedeemed" />
		</div>
		<AppBottomSheet id="split-ghost-product" :force-width="bottomSheetWidth" :active="ghostSheetActive" @toggling-popup="togglingGhostSheet">
			<div class="ghost-product-header">
				<div class="header-line">
					<div class="title">{{ getString('choose_amount') }}</div>
					<MenuButton :title="getString('confirm')" @click="confirmAmount" />
				</div>
				<div class="header-explain">{{ getString('choose_amount_explain') }}</div>
			</div>
			<div class="ghost-product-body">
				<MenuTextInput type="number" :value="partialGhostAmountEdit > 0 ? currencyStore.dbToActualValue(partialGhostAmountEdit)+'' : ''" @changed-text="changedPartialGhost" />
			</div>
		</AppBottomSheet>
	</div>
</template>

<script>
import {useActionsStore, useCartStore, useMenusStore} from "@/new-menu/stores";
import {useCurrencyStore, useLanguageStore, useSvgStore} from "@/shared/stores";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import PayTableActions from "@/new-menu/components/pay/PayTableActions.vue";
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";

export default {
	components: {MenuButton, AppBottomSheet, MenuTextInput, PayTableActions},
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let actionsStore = useActionsStore();
		let svgStore = useSvgStore();
		const currencyStore = useCurrencyStore();
		return {cartStore, currencyStore, menusStore, svgStore, getString: languageStore.getString, actionsStore}
	},
	name: "PayTableSplit",
	data() {
		return {
			selectedItems: {},
			partialGhostAmount: 0,
			partialGhostAmountEdit: 0,
			ghostSheetActive: false
		}
	},
	computed: {
		bottomSheetWidth() {
			if (this.windowWidth < 550) {
				return "100%";
			} else {
				return "550px";
			}
		},
		intentToPay() {
			let total = 0;
			this.actionsStore.tableOrders.forEach(order => {
				order.items.forEach(item => {
					if(this.selectedItems[item.order_item_id]) {
						let fullPrice = item.price;
						if(item.products) {
							fullPrice += item.products.reduce((acc, product) => {
								return acc + (product.price * product.quantity);
							}, 0);
						}
						total += this.selectedItems[item.order_item_id] * fullPrice;
					}
				});
				if(this.selectedItems['cover'+order.order_id]) {
					total += this.selectedItems['cover'+order.order_id] * order.cover_charge_value;
				}
			});
			total += this.partialGhostAmount;
			return total;
		},
		tableOrdersItems() {
			let items = [];
			let itemsCover = [];
			this.actionsStore.tableOrders.forEach(order => {
				order.items.forEach(item => {
					if(item.redeemed >= item.quantity) {
						return;
					}
					items.push(item);
				});
				if(order.cover_charge_num - order.cover_charge_paid > 0 && order.cover_charge_value > 0) {
					itemsCover.push({
						order_item_id: 'cover'+order.order_id,
						name: this.menusStore?.room?.cover_charge_nameTranslation,
						price: order.cover_charge_value,
						quantity: order.cover_charge_num - order.cover_charge_paid,
						redeemed: 0,
						products: []
					});
				}
			});
			return itemsCover.concat(items);
		},
		itemsRedeemed() {
			let items = [];
			this.actionsStore.tableOrders.forEach(order => {
				order.items.forEach(item => {
					if(this.selectedItems[item.order_item_id]) {
						items.push({
							order_item_id: item.order_item_id,
							order_id: item.order_id,
							quantity: this.selectedItems[item.order_item_id]
						});
					}
				});
				if(this.selectedItems['cover'+order.order_id]) {
					items.push({
						order_item_id: 'cover',
						order_id: order.order_id,
						quantity: this.selectedItems['cover'+order.order_id]
					});
				}
			});
			return items;
		},
		ghostValue() {
			let totalPayable = this.tableOrdersItems.reduce((acc, item) => {
				let fullPrice = item.price;
				if(item.products) {
					fullPrice += item.products.reduce((acc, product) => {
						return acc + (product.price * product.quantity);
					}, 0);
				}
				return acc + (item.quantity - item.redeemed) * fullPrice;
			}, 0);
			return Math.max(0, this.actionsStore.payBillAmount - totalPayable);
		},
	},
	methods: {
		confirmAmount() {
			this.ghostSheetActive = false;
			this.partialGhostAmount = this.partialGhostAmountEdit;
			this.partialGhostAmountEdit = 0;
		},
		changedPartialGhost(value) {
			if(value === '') {
				this.partialGhostAmountEdit = 0;
			} else {
				let amount = this.currencyStore.actualToDbValue(value);
				if(amount > this.ghostValue) {
					amount = this.ghostValue;
				} else if(amount < 0) {
					amount = 0;
				}
				this.partialGhostAmountEdit = Math.round(amount);
			}
			console.log("New partial ghost amount edit: ", this.partialGhostAmountEdit);
		},
		togglingGhostSheet(open) {
			if (!open) {
				this.ghostSheetActive = false;
			}
		},
		addItem(item) {
			if(this.selectedItems.hasOwnProperty(item.order_item_id)) {
				if(this.selectedItems[item.order_item_id] >= item.quantity - item.redeemed) {
					return;
				}
				this.selectedItems[item.order_item_id]++;
			} else {
				this.selectedItems[item.order_item_id] = 1;
			}
		}
	},
	watch: {
		ghostValue() {
			if(this.ghostValue < this.partialGhostAmount) {
				this.partialGhostAmount = this.ghostValue;
			}
		}
	}
}
</script>

<style lang="scss">
#split-ghost-product {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background-color: var(--theme-bg2-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		max-height: calc(95% - 15px);

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>
<style scoped lang="scss">
.pay-table-partial-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.content {
		width: 100%;
		flex: 1;
		padding: var(--padding-vertical) var(--padding-horizontal);
		padding-top: calc(var(--padding-vertical) * 2);
		padding-bottom: 180px;

	}

	.fixed-bottom {
		border-radius: var(--rounded-corner-l);
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		padding: var(--padding-vertical) var(--padding-horizontal);
		background-color: var(--theme-bg1-color);
		border: 2px solid var(--business-color);
	}

	.row-pay-info {
		margin-bottom: var(--padding-vertical);

		.pay-info {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt2-color);
		}

		.pay-value-container {
			display: flex;
			align-items: center;

			.pay-value-discount {
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--theme-txt2-color);
				font-weight: 700;
				font-style: normal;
				margin-right: 6px;
				text-decoration: line-through;
				text-decoration-thickness: 1.5px;
			}

			.pay-value-full {
				font-size: var(--font-size-l);
				line-height: var(--line-height-l);
				color: var(--theme-txt1-color);
				font-weight: 700;
				font-style: normal;

				&.discount {
					color: var(--business-color);
				}
			}

		}

	}

	.section-title {
		margin-bottom: var(--padding-vertical);
		font-size: var(--font-size-l);
		line-height: var(--line-height-l);
		color: var(--theme-txt1-color);
		font-weight: 700;
	}

	.items-container {
		display: flex;
		flex-direction: column;
		gap: 6px;

		.no-items {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt2-color);
		}
	}

	.clear-button {
		display: flex;
		align-items: center;
		cursor: pointer;

		.clear-icon {
			width: 18px;
			height: 18px;
			margin-right: 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:deep(svg) {
				width: 100%;
				height: 100%;
				.fill {
					fill: var(--business-color);
				}

				.stroke {
					stroke: var(--business-color);
				}
			}
		}

		p {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
		}
	}

	.section-inset {
		border-radius: var(--rounded-corner);
		box-shadow: inset var(--theme-box-shadow-xlight);
		background-color: var(--theme-bg1-color);

	}

	.section-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		flex-direction: column;

		.item-head {
			flex-wrap: nowrap;
			overflow: hidden;
		}

		.left-part {
			display: flex;
			align-items: center;
			padding: var(--padding-vertical) 0;
			padding-left: var(--padding-horizontal);
			flex-grow: 1;
			overflow: hidden;

			.item-quantity {
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--business-color);
				font-weight: 700;
				margin-right: 6px;
			}

			.item-name {
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--theme-txt1-color);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-right: 4px;
			}
		}

		.right-part {
			display: flex;
			align-items: center;
			padding-right: var(--padding-horizontal);
			flex-shrink: 0;

			.item-price {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
			}

			.select-counter-container {
				width: 38px;
				height: 32px;
				transition: width 0.3s ease;
				overflow: hidden;

				.select-counter {
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 6px;
					background-color: var(--business-color);
					border-radius: var(--rounded-corner-s);
					color: var(--business-text-color);
					font-size: var(--font-size-l);
					line-height: var(--line-height-l);
				}

				&.hidden {
					width: 0;
				}
			}

		}


		.composite-container {
			background-color: var(--theme-bg2-color);
			box-shadow: inset var(--theme-box-shadow-light);
			border-radius: var(--rounded-corner-s);
			display: flex;
			flex-direction: column;
			margin: 0 var(--padding-horizontal);
			margin-bottom: var(--padding-vertical);
			width: calc(100% - 2 * var(--padding-horizontal));

			.composite-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 6px var(--padding-horizontal);
				border-bottom: 1px solid var(--theme-bg1-color);

				&:last-child {
					border-bottom: none;
				}

				.left-composite {
					display: flex;
					align-items: center;
					overflow: hidden;
					flex-grow: 1;

					.product-quantity {
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						color: var(--business-color);
						font-weight: 700;
						margin-right: 6px;
					}

					.product-name {
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						color: var(--theme-txt1-color);
						@include truncateString(1);
					}
				}

				.right-composite {
					display: flex;
					align-items: center;

					.item-price {
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						color: var(--theme-txt2-color);
					}

				}

			}

		}
	}

	.ghost-product {
		padding: 0 var(--padding-horizontal);
		background-color: var(--theme-bg2-color);
		border-radius: var(--rounded-corner);
		border: 1px dashed var(--theme-txt2-color);
		cursor: pointer;

		.left-part {
			padding: var(--padding-vertical) 0;

			.item-name {
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--theme-txt1-color);
			}
		}

		.right-part {
			display: flex;
			align-items: center;

			.item-price {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
			}


			.select-counter-container {
				max-width: 60px;
				height: 32px;
				transition: max-width 0.3s ease;
				overflow: hidden;

				.select-counter {
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 6px;
					background-color: var(--business-color);
					border-radius: var(--rounded-corner-s);
					color: var(--business-text-color);
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					padding: 0 6px;
				}

				&.hidden {
					max-width: 0;
				}
			}
		}

	}

}

.ghost-product-header {
	padding: var(--padding-vertical) var(--padding-horizontal);
	margin: var(--padding-vertical) var(--padding-horizontal);
	background-color: var(--theme-bg1-color);
	border-radius: var(--rounded-corner);
	display: flex;
	flex-direction: column;

	.header-line {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: var(--padding-vertical);

		.title {
			font-size: var(--font-size-l);
			line-height: var(--line-height-l);
			color: var(--theme-txt1-color);
			font-weight: 700;
		}
	}

	.header-explain {
		font-size: var(--font-size);
		line-height: var(--line-height);
		color: var(--theme-txt2-color);
	}

}

.ghost-product-body {
	padding: var(--padding-vertical) var(--padding-horizontal);
	margin: var(--padding-vertical) var(--padding-horizontal);
	background-color: var(--theme-bg1-color);
	border-radius: var(--rounded-corner);

	.menu-text-input {
		background-color: var(--theme-bg2-color);
		border-radius: var(--rounded-corner);
		border: 1px solid var(--business-color);
		padding: var(--padding-vertical) 0;

		&:deep(input) {
			width: 100%;
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
		}
	}

}
</style>