<template>
	<div class="pay-table-evenly">
		<div class="section-title">{{ getString('split_evenly') }}</div>
		<div class="section-inset section-amount">
			<MenuQuantity class="input-quantity" :value="numPeople" @changed-value="v => numPeople = v" :min="1" :label="getString('number_of_people')" />
			<MenuQuantity class="input-quantity" :value="peoplePayingFor" @changed-value="v => peoplePayingFor = v" :min="1" :max="numPeople" :label="getString('people_pay_for')" />
			<div class="line" />
			<div class="amounts-rows">
				<div class="row-amount">
					<div class="amount-info">{{ getString('total_bill') }}</div>
					<div class="amount-value">{{ currencyStore.readableCurrencyValue(actionsStore.payBillAmount) }}</div>
				</div>
				<div class="row-amount">
					<div class="amount-info">{{ getString('left_to_pay') }}</div>
					<div class="amount-value">{{ currencyStore.readableCurrencyValue(actionsStore.payBillAmount - intentToPay) }}</div>
				</div>
				<div class="row-amount row-primary">
					<div class="amount-info">{{ getString('amount_to_pay') }}</div>
					<div class="amount-value">{{ currencyStore.readableCurrencyValue(intentToPay) }}</div>
				</div>
			</div>
		</div>
		<PayTableActions :amount="intentToPay" :payment-type="2" />
	</div>
</template>

<script>
import {useActionsStore, useCartStore, useMenusStore} from "@/new-menu/stores";
import {useCurrencyStore, useLanguageStore} from "@/shared/stores";
import MenuQuantity from "@/new-menu/components/generic/MenuQuantity.vue";
import PayTableActions from "@/new-menu/components/pay/PayTableActions.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let actionsStore = useActionsStore();
		const currencyStore = useCurrencyStore();
		return {cartStore, currencyStore, menusStore, getString: languageStore.getString, actionsStore}
	},
	name: "PayTableEvenly",
	components: {PayTableActions, MenuQuantity},
	data() {
		return {
			numPeople: this.getNumPeople(),
			peoplePayingFor: 1,
		}
	},
	computed: {
		intentToPay() {
			return this.numPeople * this.peoplePayingFor > 0 ? Math.round(this.actionsStore.payBillAmount / this.numPeople * this.peoplePayingFor) : this.actionsStore.payBillAmount;
		},
	},
	methods: {
		getNumPeople() {
			let numPeople = 0;
			for (let i = 0; i < this.actionsStore.tableOrders.length; i++) {
				if (this.actionsStore.tableOrders[i].cover_charge_num) {
					numPeople += this.actionsStore.tableOrders[i].cover_charge_num;
				}
			}
			return numPeople > 0 ? numPeople : 1;
		}
	},
}
</script>

<style scoped lang="scss">
.pay-table-evenly {
	padding: var(--padding-vertical) var(--padding-horizontal);

	.section-title {
		margin-bottom: var(--padding-vertical);
		font-size: var(--font-size-l);
		line-height: var(--line-height-l);
		color: var(--theme-txt1-color);
		font-weight: 700;

	}

	.section-inset {
		border-radius: var(--rounded-corner);
		box-shadow: inset var(--theme-box-shadow-xlight);
		background-color: var(--theme-bg1-color);
		padding: var(--padding-vertical) var(--padding-horizontal);
	}

	.section-amount {
		display: flex;
		flex-direction: column;
		margin-bottom: var(--padding-vertical);
	}

	.input-quantity {
		margin-bottom: 20px;
	}

	.line {
		height: 1px;
		background-color: var(--theme-txt2-color);
		width: 100%;
		margin-bottom: var(--padding-vertical);
	}

	.amounts-rows {
		width: 100%;
		gap: 4px;
		display: flex;
		flex-direction: column;

		.row-amount {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;

			.amount-info {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
			}

			.amount-value {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				font-weight: 500;
			}

			&.row-primary {
				.amount-info {
					font-size: var(--font-size-m);
					line-height: var(--line-height-m);
					color: var(--theme-txt1-color);
				}
				.amount-value {
					font-size: var(--font-size-m);
					line-height: var(--line-height-m);
					color: var(--theme-txt1-color);
					font-weight: 700;
				}
			}
		}
	}

}
</style>