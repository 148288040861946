<template>
	<div class="stripe-checkout-container">
		<div class="existing-methods-container" v-show="!newCard">
			<div class="title">{{ getString('saved_cards') }}</div>
			<div class="method-container" v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
				<div class="method" :class="{selected: paymentMethod.id === selectedExistingMethod}" @click="selectedExistingMethod = paymentMethod.id">
					<div class="method-logo"><img :src="'https://img.qromo.io/img/public/card-'+(['unionpay', 'visa', 'mastercard', 'amex'].includes(paymentMethod.brand) ? paymentMethod.brand : 'generic')+'.svg'" alt="card logo"></div>
					<div class="method-data">
						<div class="method-data-title">
							<div class="method-wallet-icon" v-if="paymentMethod.wallet_type && ['apple_pay', 'google_pay'].includes(paymentMethod.wallet_type)"><img :src="getWalletIcon(paymentMethod.wallet_type)" alt="card wallet logo"></div>
							<div class="method-brand">{{ paymentMethod.brand }}</div>
							<div class="method-last4">{{ '**** '+paymentMethod.last4 }}</div>
						</div>
						<div class="method-data-extra">
							<p>{{ getString("expires", [(paymentMethod.exp_month < 10 ? "0"+paymentMethod.exp_month : paymentMethod.exp_month), paymentMethod.exp_year]) }}</p>
						</div>
					</div>
					<div class="method-remove" v-html="getIcon('iconBin')" @click.stop="removePaymentMethod(paymentMethod.id)"></div>
				</div>
			</div>
			<div class="new-method" @click="showNewCard">
				<div class="icon-plus" v-html="getIcon('iconPlus')"></div>
				<p class="add-method-title">{{ getString('add_card') }}</p>
			</div>
			<div class="card-pay-button">
				<MenuButton :title="getString('pay_x', [currencyStore.readableCurrencyValue(amount || currencyStore?.currCurrency?.min_stripe_amount || 100)])" @click="payWithExistingMethod" />
			</div>
		</div>
		<div class="new-payment-method-container" v-show="newCard">
			<div class="express-loader-container" :class="{hidden: !loadingExpress}">
				<AppLoader :color="'#'+menusStore.business.color" :size="30" />
			</div>
			<div class="express-checkout-container" :class="{hidden: loadingExpress || expressMethods.length === 0}">
				<div class="express-checkout-element" :id="'express-checkout-element-'+uniqueId"></div>
				<div class="express-errors-container" v-if="displayExpressError">
					<div class="express-errors">{{ displayExpressError }}</div>
				</div>
			</div>
			<div class="pay-with-card-container">
				<div class="title">{{ getString('pay_with_card') }}</div>
				<div class="email-input-container" v-if="shouldAskEmailNewCard" :class="{alert: alertEmailInput}">
					<MenuTextInput ref="email-new-card" :placeholder="getString('email')" @changed-text="v => newCardEmail = v" />
				</div>
				<div class="card-element-container">
					<div class="card-element" :id="'card-element-'+uniqueId"></div>
				</div>
				<div class="card-errors-container" v-if="displayError">
					<div class="card-errors">{{ displayError }}</div>
				</div>
				<div class="card-pay-button">
					<MenuButton :title="getString('pay_x', [currencyStore.readableCurrencyValue(amount || currencyStore?.currCurrency?.min_stripe_amount || 100)])" @click="clickPayWithCard" />
				</div>
			</div>
			<div class="back-to-existing-container" v-if="paymentMethods.length > 0">
				<div class="back-to-existing" @click="newCard = false">
					<p>{{ getString('back_to_existing') }}</p>
				</div>
			</div>
		</div>
		<div class="checkout-loader" :class="{hidden: !loadingPayment}">
			<AppLoader v-if="loadingPayment" :color="'#'+menusStore.business.color" />
		</div>
	</div>
</template>

<script>
import {useCurrencyStore, useLanguageStore, useNotificationStore, useScriptStore, useSvgStore} from "@/shared/stores";
import {useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import {validateEmail} from "@/shared/helpers/strings";
import AppLoader from "@/shared/components/AppLoader.vue";
import API from '@/shared/helpers/API.js';
import {nextTick} from "vue";

export default {
	components: {AppLoader, MenuButton, MenuTextInput},
	setup() {
		let languageStore = useLanguageStore();
		let menusStore = useMenusStore();
		let cartStore = useCartStore();
		let notificationStore = useNotificationStore();
		let svgStore = useSvgStore();
		let evsStore = useEvsStore();
		let scriptStore = useScriptStore();
		const currencyStore = useCurrencyStore();
		return {getString: languageStore.getString, languageStore, currencyStore, menusStore, cartStore, notificationStore, getIcon: svgStore.get, evsStore, scriptStore}
	},
	name: "StripeCheckout",
	emits: ["remove-pm"],
	props: {
		paymentMethods: {
			type: Array,
			default: () => [],
		},
		clientSecret: {
			type: String,
			default: null,
		},
		redirectUrl: {
			type: String,
			default: null,
		},
		orderKey: {
			type: String,
			default: null,
		},
		amount: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			uniqueId: Math.random().toString(36).substring(7),
			elements: null,
			stripe: null,
			expressCheckoutElement: null,
			card: null,
			displayError: null,
			displayExpressError: null,
			loadingExpress: false,
			expressMethods: [],
			newCardEmail: null,
			alertEmailInput: false,
			loadingPayment: false,
			selectedExistingMethod: null,
			newCard: false,
			cardHasBeenInitialized: false,
			cardHasFinishedInitilization: false,
		}
	},
	computed: {
		shouldAskEmailNewCard() {
			return (this.cartStore.customerEmail === null || !validateEmail(this.cartStore.customerEmail)) && this.paymentMethods.length === 0;
		},
	},
	methods: {
		payWithExistingMethod() {
			if(this.selectedExistingMethod) {
				this.confirmPaymentCard(this.selectedExistingMethod);
			} else {
				this.showNewCard();
			}
		},
		showNewCard() {
			this.newCard = true;
			if(!this.cardHasBeenInitialized) {
				this.cardHasBeenInitialized = true;
				nextTick().then(() => {
					this.initNewCard();
					this.initExpressCheckout();
				});
			}
		},
		removePaymentMethod(paymentMethodId) {

			API.init().checkoutRemovePaymentMethod(this.menusStore.business.business_id, paymentMethodId, (r) => {
				if(r && r.result === "OK") {
					this.$emit("remove-pm", paymentMethodId);
				} else {
					this.notificationStore.showNotification("generic_fail");
				}
			});

		},
		getWalletIcon(wallet) {
			if(wallet === "apple_pay") {
				return "https://img.qromo.io/img/public/apple-logo.svg";
			} else if(wallet === "google_pay"){
				return "https://img.qromo.io/img/public/google-logo.svg";
			}
			return null;
		},
		initElements() {
			if(this.stripe === null) {
				this.stripe = Stripe(STRIPE_PK);
			}
			if(this.elements === null) {

				let appearance = {
					theme: this.menusStore.theme.light === "0" ? 'night' : 'stripe',
					variables: {},
				}

				let roundS = 6;
				switch (this.menusStore.business.options.round_level) {
					case "squared":
						roundS = 0;
						break;
					case "light":
						roundS = 4;
						break;
					default:
						break;
				}

				appearance.variables.colorBackground = '#' + this.menusStore.theme.bg_1;
				appearance.variables.colorPrimary = '#' + this.menusStore.business.color;
				appearance.variables.colorText = '#' + this.menusStore.theme.txt_1;
				appearance.variables.colorDanger = '#' + this.menusStore.theme.txt_2;
				appearance.variables.fontFamily = this.menusStore.fontFamily;
				appearance.variables.borderRadius = roundS+"px";
				appearance.variables.fontSizeBase = this.menusStore.readMode ? '20px' : '15px';
				let currencyCode = this.currencyStore.currCurrency?.code || "EUR";
				let options = {
					mode: 'payment',
					amount: this.amount || this.currencyStore?.currCurrency?.min_stripe_amount || 100,
					currency: currencyCode.toLowerCase(),
					appearance: appearance,
					fonts: [{
						cssSrc: fontFamilyLink
					}],
					paymentMethodCreation: 'manual'
				}

				const stripeSupportedLocales = [
					"auto","ar","bg","cs","da","de","el","en","en-GB","es","es-419","et","fi","fil","fr","fr-CA","he","hr","hu","id","it","ja","ko","lt","lv","ms","mt","nb","nl","pl","pt-BR","pt","ro","ru","sk","sl","sv","th","tr","vi","zh","zh-HK","zh-TW"
				];
				options.locale = this.languageStore.lang && stripeSupportedLocales.includes(this.languageStore.lang) ? this.languageStore.lang : "auto";

				this.elements = this.stripe.elements(options);

			}
		},
		initNewCard() {

			const cartExists = document.getElementById("card-element-"+this.uniqueId);
			if(!cartExists) {
				return;
			}

			this.card = this.elements.create("card", {
				iconStyle: "solid",
				style: {
					base: {
						iconColor: '#' + this.menusStore.business.color,
						color: '#' + this.menusStore.theme.txt_1,
						fontSize: this.menusStore.readMode ? '20px' : '15px',
						fontSmoothing: "auto",
						fontFamily: this.menusStore.fontFamily,

						"::placeholder": {
							color: '#' + this.menusStore.theme.txt_2 + "96",
						},
						":-webkit-autofill": {
							color: '#' + this.menusStore.business.color,
						}
					}
				}
			});
			this.card.mount("#card-element-"+this.uniqueId);

			this.card.on('change', ({error}) => {
				if (error) {
					this.displayError = error.message;
				} else {
					this.displayError = null;
				}
			});
			this.card.on('ready', () => {
				this.cardHasFinishedInitilization = true;
			});

		},
		initExpressCheckout() {

			const expressExists = document.getElementById("express-checkout-element-"+this.uniqueId);
			if(!expressExists) {
				return;
			}

			const options = {
				buttonHeight: 40,
				buttonTheme: {
					applePay: this.menusStore.theme.light === "1" ? 'black' : 'white',
					googlePay: this.menusStore.theme.light === "1" ? 'black' : 'white',
				},
				layout: {
					overflow: 'never',
				}
			}
			this.loadingExpress = true;

			this.expressCheckoutElement = this.elements.create('expressCheckout', options);
			this.expressCheckoutElement.mount('#express-checkout-element-'+this.uniqueId);
			this.expressCheckoutElement.on('ready', ({availablePaymentMethods}) => {
				this.loadingExpress = false;
				if (!availablePaymentMethods) {
					this.expressMethods = [];
				} else {
					this.expressMethods = availablePaymentMethods;
				}
			});
			this.expressCheckoutElement.on('click', (event) => {
				this.loadingPayment = true;
				this.evsStore.enqueueEvent('click', 'pay-card-express', this.orderKey);
				const options = {
					emailRequired: true,
					phoneNumberRequired: false,
					shippingAddressRequired: false,
				};
				event.resolve(options);
			});
			this.expressCheckoutElement.on('cancel', () => {
				this.loadingPayment = false;
				this.elements.update({amount: this.amount || this.currencyStore?.currCurrency?.min_stripe_amount || 100});
			});
			this.expressCheckoutElement.on('confirm', async (event) => {

				if(event && event.billingDetails && event.billingDetails.email && validateEmail(event.billingDetails.email)) {

					if(!this.cartStore.customerEmail) {
						this.cartStore.customerEmail = event.billingDetails.email;
						this.menusStore.saveData();
					}

					API.init().checkoutAddEmail(this.menusStore.business.business_id, this.cartStore.customerEmail, async (r) => {

						const {error: submitError} = await this.elements.submit();
						if (submitError) {
							this.displayExpressError = submitError.message;
							this.loadingPayment = false;
							this.evsStore.enqueueEvent('payment', 'fail', submitError.code);
							return;
						}

						const {error, paymentMethod} = await this.stripe.createPaymentMethod({
							elements: this.elements,
						});

						if (error) {
							this.displayExpressError = error.message;
							this.loadingPayment = false;
							this.evsStore.enqueueEvent('payment', 'fail', error.code);
							return;
						}

						const {error: confirmError} = await this.stripe.confirmPayment({
							clientSecret: this.clientSecret,
							confirmParams: {
								return_url: this.redirectUrl,
								payment_method: paymentMethod.id,
							},
						});

						if (confirmError) {
							this.displayExpressError = confirmError.message;
							this.loadingPayment = false;
							this.evsStore.enqueueEvent('payment', 'fail', confirmError.code);
							return;
						}

					});

				} else {
					this.displayExpressError = this.getString("invalid_email");
					this.loadingPayment = false;
					return;
				}


			});


		},
		clickPayWithCard() {
			this.confirmPaymentCard(null);
			this.evsStore.enqueueEvent('click', 'pay-card', this.orderKey);
		},
		confirmPaymentCard(paymentMethod, forceEmail = null) {
			if(paymentMethod === null && !this.cardHasFinishedInitilization) {
				return;
			}

			let email = forceEmail ? forceEmail : this.cartStore.customerEmail;
			if(this.shouldAskEmailNewCard && !forceEmail) {
				if(!this.newCardEmail || !validateEmail(this.newCardEmail)) {
					this.notificationStore.showNotification("invalid_email");
					this.$refs["email-new-card"].focus();
					this.alertEmailInput = true;
					setTimeout(() => {
						this.alertEmailInput = false;
					}, 1000);
					return;
				}
				email = this.newCardEmail;
				this.cartStore.customerEmail = email;
				this.menusStore.saveData();

				this.loadingPayment = true;
				API.init().checkoutAddEmail(this.menusStore.business.business_id, email, (r) => {
					this.confirmPaymentCard(paymentMethod, email);
				});
				return;
			}

			this.loadingPayment = true;

			let confirmOptions = {}
			let pm = paymentMethod;
			if(!pm) {
				pm = {
					card: this.card,
					billing_details: {
						email: email,
					}
				};

				confirmOptions.receipt_email = email;
			}
			confirmOptions.payment_method = pm;

			this.stripe.confirmCardPayment(this.clientSecret, confirmOptions).then((result) => {

				if (result.error) {

					this.displayError = result.error.message;
					this.loadingPayment = false;
					this.evsStore.enqueueEvent('payment', 'fail', result.error.code);

				} else {

					if (result.paymentIntent.status === 'succeeded') {
						this.paymentIsConfirmed();
					} else if(result.paymentIntent.status === "requires_action") {
						this.evsStore.enqueueEvent('payment', 'requires_action', result.error.code);

						this.stripe.confirmCardPayment(this.clientSecret).then((failed) => {
							if(failed) {
								this.displayError = result.error.message;
								this.loadingPayment = false;
								this.evsStore.enqueueEvent('payment', 'fail', result.error.code);
							} else {
								this.paymentIsConfirmed();
							}
						});

					} else {
						this.displayError = this.getString("payment_failed");
						this.loadingPayment = false;
					}
				}
			});

		},
		paymentIsConfirmed() {
			window.location.replace(this.redirectUrl);
		},
	},
	mounted() {
		this.scriptStore.loadScript("stripe", () => {
			this.initElements();
			this.showNewCard();
		});
	},
	watch: {
		paymentMethods() {
			if(this.paymentMethods.length === 0) {
				this.showNewCard();
			} else {
				this.selectedExistingMethod = this.paymentMethods[0].id;
				this.newCard = false;
			}
		},
		clientSecret(newValue) {
			if(newValue) {
				if(this.elements) {
					this.elements.update({amount: this.amount || this.currencyStore?.currCurrency?.min_stripe_amount || 100});
				}
			}
		},
	}
}
</script>

<style scoped lang="scss">
.stripe-checkout-container {
	display: flex;
	flex-direction: column;
	padding: var(--padding-vertical) var(--padding-horizontal);
	position: relative;

	.checkout-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-txt2-alpha60-color);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);
		transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;
		visibility: visible;
		opacity: 1;
		border-top-left-radius: var(--rounded-corner-l);
		border-top-right-radius: var(--rounded-corner-l);

		&.hidden {
			opacity: 0;
			backdrop-filter: blur(0px);
			-webkit-backdrop-filter: blur(0px);
			visibility: hidden;
		}
	}

	.existing-methods-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: var(--rounded-corner);
		border: 1px solid var(--business-color);
		margin-top: 4px;
		padding: 0 var(--padding-horizontal-s);

		.title {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--business-color);
			font-weight: 500;
			margin-top: -10px;
			margin-bottom: 8px;
			background-color: var(--theme-bg2-color);
			padding: 0 8px;
		}

		.method-container {
			width: 100%;
			display: flex;
			position: relative;
			z-index: 0;
			margin-bottom: 8px;

			.method {
				width: 100%;
				display: flex;
				padding: 9px;
				background-color: var(--theme-bg1-color);
				border-radius: var(--rounded-corner-s);
				cursor: pointer;
				position: relative;
				border: 2px solid transparent;
				transition: border 0.3s ease-in-out;

				&.selected {
					border: 2px solid var(--business-color);
				}

				&:after {
					content: "";
					display: block;
					width: calc(100% + 2px);
					height: calc(100% + 2px);
					box-shadow: 0 0 2px var(--theme-txt2-color);
					background: var(--theme-bg1-color);
					position: absolute;
					top: -1px;
					left: -1px;
					border-radius: var(--rounded-corner-s);
					z-index: -1;
					opacity: 0.5;
				}

				.method-logo {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;

					img {
						width: 32px;
						height: 32px;
						border-radius: var(--rounded-corner-s);
					}
				}

				.method-data {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					padding: 0 8px;
					overflow: hidden;

					.method-data-title {
						display: flex;
						align-items: stretch;
						justify-content: flex-start;
						color: var(--theme-txt1-color);

						.method-wallet-icon {
							display: flex;
							justify-content: center;
							align-items: baseline;
							margin-right: 4px;

							img {
								width: 16px;
								height: 16px;
							}
						}

						.method-brand {
							text-transform: capitalize;
							margin-right: 6px;
							font-size: var(--font-size);
							line-height: var(--line-height);
							font-weight: 500;
						}

						.method-last4 {
							font-size: var(--font-size);
							line-height: var(--line-height);
							font-weight: 500;
							@include truncateString();
						}
					}

					.method-data-extra {
						display: flex;
						align-items: center;
						justify-content: flex-start;

						p {
							color: var(--theme-txt2-color);
							font-size: var(--font-size-s);
							line-height: var(--line-height-s);
							font-weight: 400;
						}
					}
				}

				.method-remove {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
					padding: 0 4px;
					cursor: pointer;

					&:deep(svg) {
						width: 16px;
						height: 16px;

						.fill {
							fill: var(--theme-txt2-color);
						}

						.stroke {
							stroke: var(--theme-txt2-color);
						}
					}
				}
			}
		}

		.new-method {
			min-height: 40px;
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			cursor: pointer;
			border-radius: var(--rounded-corner-s);
			background-color: var(--theme-bg1-color);
			width: 100%;
			margin-bottom: 8px;

			.icon-plus {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				padding-left: 8px;

				&:deep(svg) {
					width: 16px;
					height: 16px;

					.fill {
						fill: var(--business-color);
					}

					.stroke {
						stroke: var(--business-color);
					}
				}
			}

			.add-method-title {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-grow: 1;
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--business-color);
				font-weight: 500;
				padding: 0 8px;
				@include truncateString();
			}

		}
	}

	.new-payment-method-container {
		display: flex;
		flex-direction: column;


		.express-loader-container {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 20px;
			padding-top: 6px;
			max-height: 60px;
			overflow: hidden;

			&.hidden {
				padding-bottom: 0;
				padding-top: 0;
				max-height: 0;
			}
		}

		.no-express-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 16px;

			.no-express {
				color: var(--theme-txt2-color);
			}

		}

		.express-checkout-container {
			margin-bottom: 16px;
			min-height: 40px;

			&.hidden {
				visibility: hidden;
				margin-bottom: 0;
				min-height: 0;
			}

			.express-errors-container {
				margin-top: 6px;

				.card-errors {
					font-size: var(--font-size);
					line-height: var(--line-height);
					color: var(--theme-txt2-color);
				}
			}
		}

		.pay-with-card-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: var(--rounded-corner);
			border: 1px solid var(--business-color);
			margin-top: 8px;
			padding: 0 var(--padding-horizontal-s);

			.title {
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--business-color);
				font-weight: 500;
				margin-top: -10px;
				margin-bottom: 10px;
				background-color: var(--theme-bg2-color);
				padding: 0 8px;
			}

			.email-input-container {
				margin-bottom: var(--padding-vertical);
				width: 100%;
				position: relative;
				z-index: 0;


				&:after {
					content: "";
					display: flex;
					position: absolute;
					top: -2px;
					left: -2px;
					width: calc(100% + 4px);
					height: calc(100% + 4px);
					border-radius: var(--rounded-corner-s);
					background: var(--business-color);
					opacity: 0;
					transition: opacity 0.5s ease-in-out;
					pointer-events: none;
				}

				&.alert {

					&:after {
						opacity: 0.3;
					}

				}

				.menu-text-input {
					width: 100%;
					position: relative;
					&:deep(input) {
						width: 100%;
						background-color: var(--theme-bg1-color);
						border-radius: var(--rounded-corner-s);
						position: relative;
						min-height: 36px;
					}

					&:after {
						content: "";
						display: block;
						width: calc(100% + 2px);
						height: calc(100% + 2px);
						box-shadow: 0 0 2px var(--theme-txt2-color);
						background: var(--theme-bg1-color);
						position: absolute;
						top: -1px;
						left: -1px;
						border-radius: var(--rounded-corner-s);
						z-index: -1;
						opacity: 0.5;
					}
				}
			}

			.card-element-container {
				margin-bottom: var(--padding-vertical);
				width: 100%;
				position: relative;
				z-index: 0;

				.card-element {
					width: 100%;
					padding: 9px;
					background: var(--custom-bg-color-2);
					color: var(--custom-txt-color);
					border-radius: var(--rounded-corner-s);
					position: relative;
					background: var(--theme-bg1-color);

					&:after {
						content: "";
						display: block;
						width: calc(100% + 2px);
						height: calc(100% + 2px);
						box-shadow: 0 0 2px var(--theme-txt2-color);
						background: var(--theme-bg1-color);
						position: absolute;
						top: -1px;
						left: -1px;
						border-radius: var(--rounded-corner-s);
						z-index: -1;
						opacity: 0.5;
					}
				}
			}

			.card-errors-container {
				margin-bottom: var(--padding-vertical);

				.card-errors {
					font-size: var(--font-size);
					line-height: var(--line-height);
					color: var(--theme-txt2-color);
				}
			}

		}

		.back-to-existing-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8px;

			.back-to-existing {
				cursor: pointer;

				p {
					color: var(--theme-txt2-color);
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
				}
			}
		}
	}


	.card-pay-button {
		margin-bottom: var(--padding-vertical);
		width: 100%;

		.menu-button {
			box-shadow: none;
			padding: 8px var(--padding-horizontal);

			&:deep(.menu-button-text) {
				font-size: var(--font-size);
				line-height: var(--line-height);
			}
		}
	}
}
</style>







