<template>
	<div class="pay-table-container">
		<div class="section-title">{{ getString('amount_to_pay') }}</div>
		<div class="section-inset section-total" :class="{changing: payBillAmountDelta !== 0}">
			<div class="total-amount">{{ currencyStore.readableCurrencyValue(actionsStore.payBillAmount) }}</div>
		</div>
		<div class="section-title">{{ getString('how_do_you_want_to_pay') }}</div>
		<div class="payment-options-list">
			<MenuButton :title="getString('pay_everything')" @click="customAmountType = 'total'" />
			<MenuButton :title="getString('pay_custom_amount')" outline @click="customAmountType = 'partial'" />
			<MenuButton :title="getString('split_evenly')" outline @click="splitEvenlyActive = true" />
			<MenuButton v-if="menusStore.business.plan_plugin_counter" :title="getString('pay_what_you_ordered')" outline @click="splitSheetActive = true" />
		</div>
		<AppBottomSheet id="custom-amount-bottom-sheet" :force-width="bottomSheetWidth" :active="customAmountType !== null" @toggling-popup="togglingCustomAmountSheet">
			<PayTablePartial v-if="customAmountType !== null" :amount-type="customAmountType" />
		</AppBottomSheet>
		<AppBottomSheet id="evenly-bottom-sheet" :force-width="bottomSheetWidth" :active="splitEvenlyActive" @toggling-popup="togglingEvenlySheet">
			<PayTableEvenly v-if="splitEvenlyActive" />
		</AppBottomSheet>
		<AppBottomSheet id="split-bottom-sheet" :force-width="bottomSheetWidth" :active="splitSheetActive" @toggling-popup="togglingSplitSheet">
			<PayTableSplit v-if="splitSheetActive" />
		</AppBottomSheet>
	</div>
</template>

<script>
import {useActionsStore, useCartStore, useMenusStore} from "@/new-menu/stores";
import {useCurrencyStore, useLanguageStore} from "@/shared/stores";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import PayTablePartial from "@/new-menu/components/pay/PayTablePartial.vue";
import PayTableSplit from "@/new-menu/components/pay/PayTableSplit.vue";
import PayTableEvenly from "@/new-menu/components/pay/PayTableEvenly.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		const actionsStore = useActionsStore();
		const currencyStore = useCurrencyStore();
		return {cartStore, currencyStore, menusStore, getString: languageStore.getString, actionsStore}
	},
	components: {PayTableSplit, PayTablePartial, PayTableEvenly, AppBottomSheet, MenuButton},
	name: "PayTable",
	mixins: [WindowWidth],
	data() {
		return {
			customAmountType: null,
			splitSheetActive: false,
			splitEvenlyActive: false,
			payBillAmountDelta: 0
		}
	},
	computed: {
		bottomSheetWidth() {
			if (this.windowWidth < 550) {
				return "100%";
			} else {
				return "550px";
			}
		},
	},
	methods: {
		togglingCustomAmountSheet(open) {
			if (!open) {
				this.customAmountType = null;
			}
		},
		togglingEvenlySheet(open) {
			if (!open) {
				this.splitEvenlyActive = false;
			}
		},
		togglingSplitSheet(open) {
			if (!open) {
				this.splitSheetActive = false;
			}
		},
	},
	watch: {
		'actionsStore.payBillAmount': function (newVal, oldVal) {
			if(oldVal === 0) return;
			this.payBillAmountDelta = newVal - oldVal;
			setTimeout(() => {
				this.payBillAmountDelta = 0;
			}, 1500);
		}
	}
}
</script>

<style lang="scss">
#custom-amount-bottom-sheet, #split-bottom-sheet, #evenly-bottom-sheet {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background-color: var(--theme-bg2-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		max-height: calc(95% - 15px);

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>
<style scoped lang="scss">
.pay-table-container {
	padding: var(--padding-vertical) var(--padding-horizontal);

	.section-title {
		margin-bottom: var(--padding-vertical);
		font-size: var(--font-size-l);
		line-height: var(--line-height-l);
		color: var(--theme-txt1-color);
		font-weight: 700;

	}

	.section-inset {
		border-radius: var(--rounded-corner-l);
		box-shadow: inset var(--theme-box-shadow-xlight);
		background-color: var(--theme-bg1-color);
		padding: 20px var(--padding-horizontal);
	}

	.section-total {
		margin-bottom: var(--padding-vertical);
		transition: background-color 0.3s ease;

		.total-amount {
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			font-weight: 700;
			font-style: normal;
			@include truncateString(1);
			transition: color 0.3s ease;
		}

		&.changing {
			background-color: var(--business-color-alpha40);

			.total-amount {
				color: var(--business-text-color);
			}
		}
	}

	.payment-options-list {
		display: flex;
		flex-direction: column;
		gap: var(--padding-vertical);
	}
}
</style>