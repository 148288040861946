<template>
	<div class="other-payments-container">
		<div class="section-title"><div class="title">{{ getString('other_methods_full') }}</div></div>
		<div class="ctas">
			<MenuButton v-if="voucherly" @click="$emit('checkout', 'voucherly')" :title="getString('voucherly')" :logos-carousel="['https://img.qromo.io/img/icons/voucherly.png']" />
			<MenuButton v-if="satispay" @click="$emit('checkout', 'satispay')" :title="getString('satispay')" :logos-carousel="['https://img.qromo.io/img/icons/satispay-logo-min.png']" />
			<MenuButton v-if="klarna" @click="$emit('checkout', 'klarna')" :title="getString('pay_in_3_installments')" :logos-carousel="['https://img.qromo.io/img/logo-klarna.svg']" />
			<MenuButton v-if="btc" @click="$emit('checkout', 'btc')" :title="getString('bitcoin')" :logos-carousel="['https://img.qromo.io/img/icons/btc-logo-heading.png']" />
		</div>
	</div>
</template>

<script>
import {useLanguageStore, useNotificationStore, useScriptStore, useSvgStore} from "@/shared/stores";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";

export default {
	components: {MenuButton},
	setup() {
		let languageStore = useLanguageStore();
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		let cartStore = useCartStore();
		let scriptStore = useScriptStore();
		let notificationStore = useNotificationStore();
		return {getString: languageStore.getString, getIcon: svgStore.get, menusStore, cartStore, scriptStore, notificationStore}
	},
	name: "OrderOtherCtas",
	emits: ["checkout"],
	props: {
		voucherly: {
			type: Boolean,
			default: false
		},
		satispay: {
			type: Boolean,
			default: false
		},
		klarna: {
			type: Boolean,
			default: false
		},
		btc: {
			type: Boolean,
			default: false
		}
	},
}
</script>

<style scoped lang="scss">
.other-payments-container {
	display: flex;
	flex-direction: column;
	padding: var(--padding-vertical) var(--padding-horizontal);

	.section-title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-bg2-color);
		box-shadow: inset var(--theme-box-shadow-light);
		border-radius: var(--rounded-corner);
		padding: var(--padding-vertical) var(--padding-horizontal);
		margin-bottom: var(--padding-vertical);

		.title {
			font-family: var(--font-family);
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			@include truncateString();
		}

	}

	.ctas {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		gap: var(--padding-vertical);
	}
}
</style>